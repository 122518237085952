import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import {
    AppBar,
    Toolbar,
    Typography,
    Container,
    Tab,
    Tabs,
    CircularProgress,
    Button,
    Card,
    CardContent,
    TextField,
    MenuItem,
    Switch,
    FormControlLabel,
    Box,
} from "@mui/material";
import { Brightness4, Brightness7 } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const msalConfig = {
    auth: {
        clientId: "64ff76a4-2e70-420a-99c4-224f6cad5204",
        authority: "https://login.microsoftonline.com/ac2f30f1-6005-4cdf-a4da-a25bc3e7823d/v2.0",
        redirectUri: "https://automation.accellis.com",
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
};

const pca = new PublicClientApplication(msalConfig);

function AppWrapper() {
    return (
        <MsalProvider instance={pca}>
            <App />
        </MsalProvider>
    );
}

function App() {
    const [darkMode, setDarkMode] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState("");
    const [sowSummary, setSowSummary] = useState("");
    const [clarifications, setClarifications] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [projectDetails, setProjectDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const theme = createTheme({
        palette: {
            mode: darkMode ? "dark" : "light",
            primary: {
                main: "#8cc63f", // Green color from the logo
            },
            secondary: {
                main: "#ffffff", // White complementing color
            },
            background: {
                default: darkMode ? "#000000" : "#f4f4f4", // Black for dark mode
            },
        },
    });

    useEffect(() => {
        if (isAuthenticated) {
            instance
                .acquireTokenSilent({
                    scopes: ["api://64ff76a4-2e70-420a-99c4-224f6cad5204/access_as_user"],
                    account: accounts[0],
                })
                .then((response) => {
                    const token = response.accessToken;
                    axios
                        .get("https://projectautomationapi-frgve6dqdghdc5de.eastus2-01.azurewebsites.net/fetch_companies", {
                            headers: { Authorization: `Bearer ${token}` },
                        })
                        .then((response) => setCompanies(response.data))
                        .catch((err) => alert("Error fetching companies: " + err.message));
                })
                .catch((err) => alert("Error acquiring token: " + err.message));
        }
    }, [isAuthenticated, instance, accounts]);

    useEffect(() => {
        document.body.style.backgroundColor = darkMode ? "#000000" : "#f4f4f4";
    }, [darkMode]);

    const handleLogin = () => {
        instance.loginPopup().catch((e) => alert("Login failed: " + e.message));
    };

    const handleLogout = () => {
        instance.logoutPopup().catch((e) => alert("Logout failed: " + e.message));
    };

    const handleGetClarifications = () => {
        if (!sowSummary) {
            alert("Please enter a summary SOW to get clarifications.");
            return;
        }
        setLoading(true);
        instance
            .acquireTokenSilent({
                scopes: ["api://64ff76a4-2e70-420a-99c4-224f6cad5204/access_as_user"],
                account: accounts[0],
            })
            .then((response) => {
                const token = response.accessToken;
                axios
                    .post(
                        "https://projectautomationapi-frgve6dqdghdc5de.eastus2-01.azurewebsites.net/get_clarification",
                        { summarize_sow: sowSummary },
                        { headers: { Authorization: `Bearer ${token}` } }
                    )
                    .then((response) => {
                        setClarifications(response.data.clarifications || []);
                        setLoading(false);
                    })
                    .catch((err) => {
                        alert("Error getting clarifications: " + err.message);
                        setLoading(false);
                    });
            })
            .catch((err) => alert("Error acquiring token: " + err.message));
    };

    const handleGenerateProject = () => {
        if (!selectedCompany || !sowSummary || !startDate || !endDate) {
            alert("Please fill out all fields before generating the project.");
            return;
        }
        setLoading(true);
        instance
            .acquireTokenSilent({
                scopes: ["api://64ff76a4-2e70-420a-99c4-224f6cad5204/access_as_user"],
                account: accounts[0],
            })
            .then((response) => {
                const token = response.accessToken;
                axios
                    .post(
                        "https://projectautomationapi-frgve6dqdghdc5de.eastus2-01.azurewebsites.net/generate_project",
                        {
                            summarize_sow: sowSummary,
                            estimated_start: `${startDate}T00:00:00Z`,
                            estimated_end: `${endDate}T00:00:00Z`,
                        },
                        { headers: { Authorization: `Bearer ${token}` } }
                    )
                    .then((response) => {
                        setProjectDetails(response.data);
                        setLoading(false);
                    })
                    .catch((err) => {
                        alert("Error generating project: " + err.message);
                        setLoading(false);
                    });
            })
            .catch((err) => alert("Error acquiring token: " + err.message));
    };

    const handleImportToConnectWise = () => {
        if (!projectDetails) {
            alert("No project details to import.");
            return;
        }
        setLoading(true);
        instance
            .acquireTokenSilent({
                scopes: ["api://64ff76a4-2e70-420a-99c4-224f6cad5204/access_as_user"],
                account: accounts[0],
            })
            .then((response) => {
                const token = response.accessToken;
                axios
                    .post(
                        "https://projectautomationapi-frgve6dqdghdc5de.eastus2-01.azurewebsites.net/create_project",
                        { ...projectDetails, company_id: selectedCompany },
                        { headers: { Authorization: `Bearer ${token}` } }
                    )
                    .then(() => {
                        alert("Project successfully imported to ConnectWise!");
                        setLoading(false);
                    })
                    .catch((err) => {
                        alert("Error importing project to ConnectWise: " + err.message);
                        setLoading(false);
                    });
            })
            .catch((err) => alert("Error acquiring token: " + err.message));
    };

    const calculateBudgetHours = (phases) => {
        if (!phases || !Array.isArray(phases)) return 0;
        return phases.reduce((total, phase) => total + (phase.budgetHours || 0), 0);
    };

    const renderProjectDetails = () => {
        if (!projectDetails) {
            return <Typography>No project details available.</Typography>;
        }

        const { phases } = projectDetails;
        const totalBudgetHours = calculateBudgetHours(phases);

        return (
            <Card sx={{ mt: 2, p: 2 }}>
                <CardContent>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Generated Project Details
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: "bold", mb: 2 }}>
                        Total Budget Hours: {totalBudgetHours} hours
                    </Typography>

                    {phases.map((phase, index) => (
                        <Card key={index} sx={{ mb: 2, border: 1, borderColor: "primary.main" }}>
                            <CardContent>
                                <Typography
                                    variant="h6"
                                    sx={{ backgroundColor: "primary.main", color: "white", p: 1 }}
                                >
                                    Phase: {phase.name}
                                </Typography>
                                <Typography sx={{ mt: 2 }}>
                                    <strong>Budget Hours:</strong> {phase.budgetHours}
                                </Typography>
                                <ul>
                                    {phase.tasks.map((task, idx) => (
                                        <li key={idx}>
                                            <strong>Task:</strong> {task.summary} ({task.budgetHours} hours)
                                        </li>
                                    ))}
                                </ul>
                            </CardContent>
                        </Card>
                    ))}
                </CardContent>

                <Button
                    variant="contained"
                    color="success"
                    fullWidth
                    sx={{ mt: 3 }}
                    onClick={handleImportToConnectWise}
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24} /> : "Import to ConnectWise"}
                </Button>
            </Card>
        );
    };

    const renderClarifications = () => {
        if (!clarifications.length) {
            return <Typography>No clarifications available.</Typography>;
        }

        return (
            <Card sx={{ mt: 2, p: 2 }}>
                <CardContent>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Clarifications
                    </Typography>
                    <ul>
                        {clarifications.map((clarification, index) => (
                            <li key={index}>
                                <Typography variant="body2">{clarification}</Typography>
                            </li>
                        ))}
                    </ul>
                </CardContent>
            </Card>
        );
    };

    return (
        <ThemeProvider theme={theme}>
            <div style={{ minHeight: "100vh" }}>
                <AppBar position="sticky">
                    <Toolbar>
                        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
                            <img
                                src="https://gm8-accellis-cdn.b-cdn.net/wp-content/uploads/2023/08/Accellis-FC.png"
                                alt="Accellis Logo"
                                style={{ maxHeight: "40px" }}
                            />
                        </Box>
                        <FormControlLabel
                            control={<Switch checked={darkMode} onChange={() => setDarkMode(!darkMode)} />}
                            label={darkMode ? <Brightness4 /> : <Brightness7 />}
                        />
                    </Toolbar>
                </AppBar>

                <Container sx={{ mt: 4 }}>
                    {!isAuthenticated ? (
                        <Button variant="contained" color="primary" fullWidth onClick={handleLogin}>
                            Login
                        </Button>
                    ) : (
                        <Button variant="contained" color="secondary" fullWidth onClick={handleLogout}>
                            Logout
                        </Button>
                    )}

                    {isAuthenticated && (
                        <>
                            <Tabs
                                value={tabValue}
                                onChange={(e, newValue) => setTabValue(newValue)}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                <Tab label="Input Panel" />
                                <Tab label="Clarifications" />
                                <Tab label="Project Details" />
                            </Tabs>

                            {tabValue === 0 && (
                                <Card sx={{ mt: 2 }}>
                                    <CardContent>
                                        <Typography variant="h6">Input Panel</Typography>
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            label="Select Company"
                                            select
                                            value={selectedCompany}
                                            onChange={(e) => setSelectedCompany(e.target.value)}
                                        >
                                            {Object.keys(companies).map((name) => (
                                                <MenuItem key={name} value={companies[name]}>
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            label="SOW Summary"
                                            multiline
                                            rows={3}
                                            value={sowSummary}
                                            onChange={(e) => setSowSummary(e.target.value)}
                                        />
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            label="Start Date"
                                            type="date"
                                            InputLabelProps={{ shrink: true }}
                                            value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                        />
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            label="End Date"
                                            type="date"
                                            InputLabelProps={{ shrink: true }}
                                            value={endDate}
                                            onChange={(e) => setEndDate(e.target.value)}
                                        />
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            sx={{ mt: 2 }}
                                            onClick={handleGetClarifications}
                                            disabled={loading}
                                        >
                                            {loading ? <CircularProgress size={24} /> : "Get Clarifications"}
                                        </Button>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            sx={{ mt: 2 }}
                                            onClick={handleGenerateProject}
                                            disabled={loading}
                                        >
                                            {loading ? <CircularProgress size={24} /> : "Generate Project"}
                                        </Button>
                                    </CardContent>
                                </Card>
                            )}

                            {tabValue === 1 && renderClarifications()}

                            {tabValue === 2 && renderProjectDetails()}
                        </>
                    )}
                </Container>
            </div>
        </ThemeProvider>
    );
}

export default AppWrapper;
